.custom-btn {
    background-color: #4CAF50; /* Green */
    border: none;
    color: white;
    padding: 8px 16px; /* Reduced padding */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px; /* Reduced font size */
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
    min-width: 120px; /* Reduced width */
    height: 40px; /* Reduced height */
}

.custom-btn:hover {
    background-color: #45a049; /* Dark green */
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Shadow on hover */
}