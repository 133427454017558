.custom-btn-mint {
    background-color: #007bff; /* Bootstrap primary blue */
    border: none;
    color: white;
    padding: 8px 16px; /* Reduced padding */
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 14px; /* Reduced font size */
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 12px;
    width: 120px; /* Reduced width */
    height: 40px; /* Reduced height */
}

.custom-btn-mint:hover {
    background-color: #0056b3; /* Darker blue */
    color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* Shadow on hover */
}


.adm-btn-circle {
    width: 30px; /* Original size */
    height: 30px;
    border-radius: 50%;
    border: 1px solid #007bff;
    background-color: #e0f0ff; /* Light blue background */
    color: #007bff; /* Primary color */
    display: flex;
    align-items: center; /* Center vertically */
    justify-content: center; /* Center horizontally */
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    padding: 0; /* Remove any internal padding */
    line-height: 0; /* Eliminate extra height space */
}

.adm-btn-circle:hover {
    background-color: #c0e0ff; /* Slightly darker blue */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
