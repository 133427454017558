.common-item {
    min-height: 320px;
    width: 9rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    transition: background-color 0.5s ease-out;
}

.common-item:hover {
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.20);
    text-decoration: none;
}

.featured-item {
    height: 26rem;
    width: 30rem;
    box-shadow: 0 10px 20px rgba(0, 0, 0, .1);
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(20px);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 10px;
    transition: background-color 0.5s ease-out;
}

@media (max-width: 40rem) {
    .featured-item {
        width: 75vw;
    }
}

.featured-item img {
    height: 50%;
    width: 100%;
    object-fit: cover;
}

.featured-item p {
    text-align: justify;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    margin: 2px;
}

.featured-item:hover {
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.20);
    text-decoration: none;
}

.featured-item-buttons-panel {
    display: flex;
    position: absolute;
    justify-content: space-between;
    bottom: 2px;
    left: 4px;
    right: 4px;
}

.featured-item-buttons-panel .btn {
    min-width: 30%;
}

.carousel img {
    border-radius: 20px;
}
  
.card.game-view-card {
    background: rgba(90, 88, 84, 0.3);
    border-radius: 15px;
}

.card.game-view-card .card-img-top {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.card.progress-view-card {
    background: rgba(90, 88, 84, 0.3);
    border-radius: 15px;
    color: #FFFFFF;
}

.card.progress-view-card .card-img-top {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
}

.game-description-text {
    text-indent: 50px;
    text-align: justify;
}

.game-info-left-part {
    flex: 1 1 15rem;
}

.game-info-right-part {
    flex: 2 1 60%;
    gap: 20px;
}

.game-info-main-panel {
    display: inline-flex;
    align-items: normal;
    flex-wrap: wrap;
    gap: 10px;
}

.game-info-main-panel .btn {
    min-width: 105px;
}
