@font-face {
  font-family: Poppins;
  src: url(static/fonts/Poppins-Regular.ttf);
  font-weight: 400;
}

@font-face {
  font-family: Poppins;
  src: url(static/fonts/Poppins-Medium.ttf);
  font-weight: 500;
}

@font-face {
  font-family: Poppins;
  src: url(static/fonts/Poppins-SemiBold.ttf);
  font-weight: 600;
}

@font-face {
  font-family: Poppins;
  src: url(static/fonts/Poppins-Bold.ttf);
  font-weight: 700;
}

@font-face {
  font-family: 'NewRocker-Regular';
  src: url('static/NewRocker-Regular.ttf') format('truetype');
}

body {
  font-family: NewRocker-Regular !important;
}